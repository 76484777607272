<template>
  <div class="container">
    <div v-for="item in selectMenu.children" :key="item.code" class="nav-item">
      <!-- 三级菜单 -->
      <div v-if="item.children && item.children.length > 0" class="nav-3">
        <div>{{ item.menuName }}</div>
        <div class="btn-box">
          <template v-for="childItem in item.children">
            <span class="menu-btn" style="margin: 0 3px" :key="childItem.code">
              <el-button
                plain
                type="warning"
                size="mini"
                :class="{ 'button-color': true, 'button-active': childItem.isActive }"
                :key="childItem.code"
                @click="jump(childItem)"
              >
                <a target="_blank" @click="aLeftClick" :href="baseUrl + childItem.href">
                  {{ childItem.menuName }}
                </a></el-button
              >
              <span v-if="childItem.backlog" class="red_spot"></span>
            </span>
          </template>
        </div>
      </div>
      <!-- 三级跳转连接 -->
      <div v-else class="a-3">
        <span class="menu-btn">
          <el-button
            plain
            type="warning"
            size="small"
            @click="jump(item)"
            :class="{ 'button-color': true, 'button-active': item.isActive }"
          >
            <a target="_blank" @click="aLeftClick" :href="baseUrl + item.href">
              {{ item.menuName }}
            </a>
          </el-button>
          <span v-if="item.backlog" class="red_spot"></span>
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'HorizontalMenuEle',
  components: {
    HorizontalMenuEleItem: () => import('./HorizontalMenuEleItem.vue'),
  },
  inject: ['reload'],
  data() {
    return {
      activeIndex: this.$route.name,
      baseUrl: window.location.origin + window.location.pathname + '#',
    }
  },
  computed: {
    ...mapState({
      menus: state => state.user.menus,
      selectMenu: state => state.user.selectMenu,
    }),
  },
  watch: {
    $route: {
      handler() {
        //当页面刷新时，需要通过url地址反推菜单对象
        if (this.$route.matched && this.$route.matched.length > 0) {
          this.findCharInTree(this.menus, this.$route.matched[this.$route.matched.length - 1].name)
        }
        this.$forceUpdate()
      },
      immediate: true,
    },
  },
  methods: {
    // openNewWindow(event, title) {
    //   // 阻止默认的右键菜单
    //   // event.preventDefault()
    //   const newWindow = window.open(event.target.href, "_blank");
    //   if (newWindow) {
    //     newWindow.document.title = title;
    //   }
    // },
    aLeftClick(e) {
      e.preventDefault()
    },
    findCharInTree(tree, char) {
      // 定义递归函数
      const deactivateNode = node => {
        if (node.code === char) {
          //找到了与当前路由匹配的菜单对象
          node.isActive = true
          return
        }
        if (node.children) {
          node.children.forEach(child => {
            deactivateNode(child) // 递归调用，处理子节点
          })
        }
      }
      //调用递归函数
      tree.forEach(rootNode => {
        deactivateNode(rootNode)
      })
    },
    jump(item) {
      // 移除红点
      if (item.backlog) {
        this.$api.menu.read(item.code).then(res => {})
      }
      // 高亮
      this.selectMenu.children?.forEach(menuItem => {
        if (menuItem.children && menuItem.children.length > 0) {
          menuItem.children.forEach(childItem => {
            childItem.isActive = false
          })
        } else {
          menuItem.isActive = false
        }
      })
      item.isActive = true
      item.backlog = false
      this.$forceUpdate()

      //跳转
      if (item.code == this.$route.name) {
        this.reload()
      } else {
        var path = item.linkTo ? item.linkTo : item.href
        this.$router.push(path)
      }
    },
  },
}
</script>
<style lang="scss" scoped>
@import '@/styles/config.scss';
/deep/.el-menu.el-menu--horizontal {
  border: none;
  > .el-menu-item {
    background: none !important;
    border: none;
    color: $blue;
    font-size: 16px;
  }
  > .el-submenu {
    .el-submenu__title {
      background: none !important;
      border: none;
      color: $blue;
      font-size: 16px;
      .el-submenu__icon-arrow {
        color: inherit;
      }
    }
    &.is-active {
      .el-submenu__title {
        border-bottom: 3px solid $yellow;
        color: $yellow;
      }
    }
    .el-menu {
      background: #fff !important;
    }
    .el-menu-item {
      background: none !important;
      &.is-active {
        color: $blue;
      }
      &:hover {
        color: #fff;
        background: $blue !important;
      }
    }
  }
}
.container {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .nav-item {
    display: flex;
    align-items: center;
    height: 80%;
    text-align: center;
    padding: 0 10px;
    border-right: 1px dashed #fff;
    color: #e6a23c;
    &:last-child {
      border: none;
    }

    .nav-3 {
      font-size: 14px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      .btn-box {
        display: flex;
        margin-top: 5px;
      }
    }
    .a-3 {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    a {
      display: inline-block;
      height: 100%;
      width: 100%;
      text-align: center;
    }
  }
}
.button-active {
  background: #e6a23c !important;
  border-color: #e6a23c !important;
  color: #fff !important;
}
.button-color {
  color: #333;
}
.menu-btn {
  position: relative;
}
.red_spot {
  width: 8px;
  height: 8px;
  margin-left: 10px;
  border-radius: 50%;
  display: inline-block;
  background: #ff0000a3;
  vertical-align: middle;
  position: absolute;
  top: 3px;
  right: 4px;
}
</style>
